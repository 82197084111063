(function(angular, undefined) {
  angular.module("kljDigitalLibraryApp.constants", [])

.constant("appConfig", {
	"userRoles": [
		"guest",
		"user",
		"admin"
	],
	"HEADER_HEIGHT_1024": 46,
	"MOBILE_NUMBER_REGEX": {},
	"CONTENT_TYPE_JSON": {
		"Content-Type": "application/json"
	},
	"EMPTY_STRING": "",
	"SUCCESS_UPDATE": "You have successfully updated",
	"CREATE_RECORD_SUCCESS_MESSAGE": "You have successfully created",
	"PAGINATION_LIMIT": 10,
	"FAILED_SERVER_MESSAGE": "No response from the server",
	"ERROR_UPDATE": "Failed to update",
	"CASE_SECTIONS": [
		"Acts Reffered",
		"Section Reffered",
		"Head Note",
		"Case Reference"
	],
	"FILTER_DATE_OPTIONS": [
		"Exact",
		"Range"
	],
	"CASE_LIST_MAPPING": {
		"bench_type": "Bench",
		"subject_subject": "Subject",
		"subject_section": "Subject",
		"party_type": "Party",
		"party_name": "Party",
		"judge_name": "Judge",
		"judgement_date_start_date": "Judgement Date",
		"judgement_date_end_date": "Judgement Date",
		"judgement_date_exact_date": "Judgement Date",
		"page_number_page_number": "Citation",
		"page_number_volume": "Citation",
		"page_number_year": "Citation",
		"court_court": "Court",
		"writ_petition_query": "Writ Petition",
		"statues_query": "Statues",
		"reference_query": "Reference",
		"counsel_name": "Counsel",
		"citation_year": "Citation",
		"citation_volume": "Citation",
		"citation_page_number": "Citation",
		"party_petitioner_name": "Party",
		"party_respondent_name": "Party"
	},
	"JOURNAL_LIST_MAPPING": {
		"journal_topic": "Topic",
		"journal_full_text": "Full Text"
	},
	"STATUES_LIST_MAPPING": {
		"statues_act": "Act",
		"statues_topic": "Topic",
		"statues_full_text": "Full Text"
	},
	"NO_OF_OPENED_TABS": 5
})

;
})(angular);